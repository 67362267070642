import Loading from './Loading'

const CenteredLoading = () => {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 10,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Loading />
    </div>
  )
}

export default CenteredLoading
